.doctor-detail-container {
  height: 500px;
  .intro-doctor {
    display: flex;
    padding: 10px 100px;
    border-top: 1px solid #ddd;
    .content-left {
      width: 20%;
      background: center center no-repeat;
      background-size: cover;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin: 10px 20px;
    }
    .content-right {
      width: 80%;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      .up {
        padding-top: 15px;
        font-size: 20px;
        font-weight: 600;
      }
      .down {
        padding-top: 10px;
        .like-share-plugin {
          margin: 10px 0 20px 0;
        }
      }
    }
  }
  .schedule-doctor {
    margin: 0 100px;
    display: flex;
    padding: 10px 0;
    min-height: 200px;
    .content-left {
      width: 50%;
    }
    .content-right {
      width: 50%;
    }
  }
  .detail-infor-doctor {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 10px 100px;
    h1,
    h2,
    h3 {
      font-size: 17px;
    }
    img {
      width: 600px;
      margin-left: 400px;
    }
  }
  .comment-doctor {
    padding: 10px 100px;
  }
  .star-rating {
    padding: 10px 100px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}
