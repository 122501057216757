.manage-patient-container {
  .m-p-title {
    font-size: 15px;
    margin: 10px 0;
    text-align: center;
    text-transform: uppercase;
  }
  .manage-patient-body {
    padding: 0 20px;
    .table-manage-patient {
      padding-top: 15px;
      #customers {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }

      #customers td,
      #customers th {
        border: 1px solid #ddd;
        padding: 8px;
      }

      #customers tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      #customers tr:hover {
        background-color: #ddd;
      }

      #customers th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #04aa6d;
        color: white;
      }
      button {
        border: none;
        outline: none;
        cursor: pointer;
      }
      .mp-btn-confirm {
        margin-right: 10px;
        height: 30px;
        border-radius: 3px;
        background: orange;
      }
      .mp-btn-call {
        margin-right: 10px;
        height: 30px;
        border-radius: 3px;
        background: rgb(87, 171, 255);
      }
      .mp-btn-delete {
        margin-right: 10px;
        height: 30px;
        border-radius: 3px;
        background: rgb(255, 0, 34);
        width: 60px;
      }
    }
  }
}
