#TableManageUser {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#TableManageUser td,
#TableManageUser th {
  border: 1px solid #ddd;
  padding: 8px;
}

#TableManageUser tr:nth-child(even) {
  background-color: #f2f2f2;
}

#TableManageUser tr:hover {
  background-color: #ddd;
}

#TableManageUser th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.user-footer {
  padding-top: 20px;
}
.search-container {
  .input-search {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url("../../../assets/searchicon.png");
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
  }
}
