//reset slick slider css
.slick-prev {
  left: -10px !important;
  z-index: 100 !important;
  height: 48px !important;
  width: 44px !important;
  background: #fff !important;
  border: 2px solid #d7d7d7 !important;
  &:hover {
    background: #e3e3e3 !important;
  }
}
.slick-prev:before,
.slick-next::before {
  font-family: FontAwesome !important;
  color: red !important;
}
.slick-next {
  right: -10px !important;
  z-index: 100 !important;
  height: 48px !important;
  width: 44px !important;
  background: #fff !important;
  border: 2px solid #d7d7d7 !important;
  &:hover {
    background: #e3e3e3 !important;
  }
}
.slick-disabled {
  display: none !important;
}
.section-share {
  &.section-specialty {
    background: #f5f5f5;
  }
  &.section-outstanding-doctor {
    background: #f5f5f5;
    height: auto;
  }
  &.section-about {
    height: 500px !important;
    border-top: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
  .section-body {
    .section-customize {
      .bg-image {
        height: 170px;
        width: 315px;
        background: center center no-repeat;
        background-size: cover;
        background-color: #eee;
        &.section-specialty {
          // background-image: url("../../assets/specialty/co-xuong-khop.jpg");
        }
        &.section-medical-facility {
          // background-image: url("../../assets/medical-facility/benh-vien-da-khoa.jpg");
        }
        &.section-outstanding-doctor {
          // background-image: url("../../assets/outstanding-doctor/doctor2.jpg");
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }
        &.section-handbook {
          // background-image: url("../../assets/handbook/handbook.jpg");
        }
      }
    }
  }
}
.section-share {
  height: 330px;

  .section-container {
    margin: 0 100px;
    display: flex;
    flex-direction: column;

    .section-header {
      height: 60px;
      margin-bottom: 30px;
      padding-top: 30px;

      .title-section {
        font-size: 22px;
        font-weight: 600;
      }

      .btn-section {
        float: right;
        padding: 10px 15px;
        border: none;
        outline: none;
        display: block;
        background: #ebebeb;
        color: black;
        cursor: pointer;
        text-transform: uppercase;
        &:hover {
          color: #fff;
          background: #f7d800;
        }
      }
    }
  }
}

.section-outstanding-doctor {
  .outer-bg {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .section-customize {
    padding: 5px;
    cursor: pointer;
    .customize-border {
      border: 1px groove #bab8b7;
      padding: 20px;
      background-color: #ffffff;
    }
  }
}
.home-footer {
  text-align: center;
  padding-top: 8px;
  a {
    text-decoration: unset;
  }
}
.section-about {
  padding: 0 100px;
  .section-about-header {
    padding: 20px 10px;
    font-size: 22px;
    font-weight: 600;
  }
  .section-about-content {
    display: flex;
    gap: 15px;
    width: 100%;
    .content-left {
      width: 60%;
    }
    .content-right {
      width: 40%;
      padding-top: 10px;
      p {
        text-align: justify;
        font-style: italic;
        margin-bottom: 0.15rem;
      }
    }
  }
}
