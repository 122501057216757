.detail-specialty-container {
  .description-specialty {
    background-color: #fff !important;
    box-shadow: 0 1px 6px #20212447;
    border-radius: 18px;
    padding: 10px;
    margin: 15px 0;
    height: auto;

    .detail-clinic {
      .title {
        margin: 15px 0 !important;
      }
      &.show-detail-clinic {
        height: 300px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-mask-image: linear-gradient(black, transparent);
        mask-image: linear-gradient(black, transparent);
      }
    }
    .show-detail {
      padding: 10px;
      text-align: center;
      color: #0d6efd;
      cursor: pointer;
      font-weight: 500;
      font-size: larger;
      background-color: #fff;
      box-shadow: 0 1px 6px #20212447;
      border-radius: 18px;
    }
  }
  .search-sp-doctor {
    select {
      height: 30px;
      padding: 5px 8px;
      border: none;
      outline: none;
      border-radius: 3px;
      cursor: pointer;
    }
  }
  .detail-specialty-body {
    padding: 0 100px;
    background: #eee;
    display: flex;
    flex-direction: column;
  }
  .each-doctor {
    display: flex;
    width: 100%;
    min-height: 300px;
    margin: 15px 0;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 1px 6px #20212447;
    border-radius: 18px;
    .dt-content-left {
      width: 50%;
      border-right: 1px solid #ddd;
      .profile-doctor-container {
        .dow {
          padding: 5px;
        }
      }
      .location {
        padding-top: 15px;
        padding-left: 20px;
      }
    }
    .dt-content-right {
      width: 50%;
      padding: 5px;
      .doctor-schedule {
        .doctor-schedule-container {
          border-right: unset !important;
        }
      }
      .doctor-extra-infor {
        border-top: 1px solid #ddd;
        margin-top: 10px;
        padding-top: 10px;
        .doctor-extra-infor-container {
          padding-left: 0 !important;
        }
      }
    }
  }
}
