@import "../../styles/common.scss";

.header-container {
  z-index: 99;
  display: flex;
  justify-content: space-between;
  background-color: $colormain;
  color: $common-text-color;
  height: 40px;
  position: relative;
  .btn-logout {
    color: $common-text-color;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    &:hover {
      background-color: darken($colormain, 5);
    }
    i {
      font-size: $base-size + 1px;
    }
  }
  .languages {
    display: flex;
    flex-direction: row;
    .welcome {
      margin-right: 10px;
      margin-top: 10px;
    }
    // .language-en {
    //   margin-right: 15px;
    //   margin-left: 10px;
    //   cursor: pointer;
    //   &.active {
    //     opacity: 1;
    //     color: #000000;
    //   }
    // }
    // .language-vi {
    //   cursor: pointer;
    //   &.active {
    //     opacity: 1;
    //     color: orange;
    //   }
    // }
    .change-language {
      .dropdown-language {
        position: relative;
        display: inline-block;
      }
      .dropdown-btn {
        padding: 9px 13px;
        width: 110px;
        background-color: #0071ba;
        /* font-family: "Courier New", Courier, monospace; */
        /* color: #fff; */
        border: none;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        align-items: center;
        span {
          padding-left: 5px;
          color: white;
        }
      }
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #fff;
        min-width: 130px;
        box-shadow: 0px 8px 16px 0px rgba(21, 18, 18, 0.2);
        z-index: 1;
        border-radius: 5px;
        padding: 10px 0;
        right: -15px;
      }
      .dropdown-content button {
        border: none;
        width: 100%;
        background-color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-family: "Courier New", Courier, monospace;
      }
      .dropdown-content button img {
        margin-right: 5px;
      }
      .dropdown-content button:hover {
        background-color: #c1c1c1;
      }
      .dropdown-language:hover .dropdown-content {
        display: block;
      }
    }
  }
}
