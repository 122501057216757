.manage-doctor-container {
  padding: 40px;
  .manage-doctor-title {
    text-align: center;
    margin: 15px 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .save-content-doctor {
    margin-bottom: 30px;
    margin-top: 20px;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 5px;
    background: orange;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .create-content-doctor {
    margin-bottom: 30px;
    margin-top: 20px;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 5px;
    background: #0866ff;
    color: white;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .more-infor {
    .content-left {
      // padding-bottom: 15px;
    }
    .content-right {
      padding: 15px 0;
    }
  }
  .manage-doctor-editor {
    padding-top: 15px;
  }
}
