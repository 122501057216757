#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.btn-edit {
  width: 50px;
  border: none;
  outline: none;
  background: transparent;
  color: orange;

  &:hover {
    background-color: transparent !important;
    color: orange !important;
  }
}

.btn-delete {
  width: 50px;
  border: none;
  outline: none;
  background: transparent;
  color: rgb(252, 44, 12);

  &:hover {
    background-color: transparent !important;
    color: rgb(255, 68, 0) !important;
  }
}

.modal-user-container {
  .modal-content{
    .modal-header{
      button {
        background-color: transparent !important;
        color: black;
        border: none;
        font-size: 27px;
      }
    }
  }

  .modal-user-body {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    .input-container {
      display: flex;
      flex-direction: column;
      width: 49%;

      &.max-width-input {
        width: 100%;
      }

      input {
        border-radius: 3px;
        border: 1px solid grey;
        height: 30px;
        outline: none;
        padding: 0 10px;
      }
    }
  }
}
