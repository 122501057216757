.preview-img-container {
  .label-upload {
    padding: 6px;
    border-radius: 3px;
    background: #ddd;
    width: 113px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 5px;

    &:hover {
      opacity: 0.8;
    }
  }

  .preview-image {
    height: 80px;
    width: 112px;
    border: 1px solid #ddd;
    background: center center no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}

.user-redux-body {
  // .search {
  //   width: fit-content;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 10px;
  //   border-radius: 25px;
  //   background: #f7d800;
  //   margin-left: 12px;
  //   margin-bottom: 10px;
  //   i {
  //     margin: 0 5px;
  //   }
  //   .input-search {
  //     background-color: #f7d800;
  //     border: none;
  //   }
  // }
  
}
