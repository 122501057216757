.form-edit-password {
    padding: 0 300px;
}
.login-input{
    margin: 7px 0;
    .custom-input-password {
        position: relative;

        i {
            position: absolute;
            right: 7px;
            top: 10px;
            cursor: pointer;
        }
    }
}