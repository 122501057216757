.verify-email-container {
  .intro-booking {
    font-size: 17px;
    font-weight: 600;
    margin-top: 30px;
    text-align: center;
    text-transform: uppercase;
    color: red;
  }
}
