.profile-doctor-container {
  .intro-doctor {
    display: flex;
    .content-left {
      width: 20%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
    .content-right {
      width: 80%;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      .up {
        font-weight: 600;
      }
      .down {
        text-align: justify;
      }
    }
  }
  .view-detail-doctor {
    padding-top: 15px;
    padding-left: 23px;
  }
  .price {
    padding-top: 10px;
  }
}
