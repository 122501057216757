.home-header-container {
  height: 60px;
  width: 100%;
  padding: 0 20px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  .home-header-content {
    width: 100%;
    height: 100%;
    display: flex; // mac dinh se tren 1 hang
    .left-content {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      .header-logo {
        background: url(../../assets/logo.png) center center no-repeat;
        height: 100%;
        width: 20%;
        margin-left: 15px;
        cursor: pointer;
      }
      i {
        font-size: 25px;
        color: gray;
        cursor: pointer;
      }
    }
    .center-content {
      width: 55%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .child-content {
        cursor: pointer;
        .subs-title {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    .right-content {
      width: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .support {
        padding-right: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 15px;
          margin-right: 5px;
        }
      }
      .language-vi {
        cursor: pointer;
        opacity: 0.2;
        &.active {
          opacity: 1;
          span {
            color: orange;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .language-japan {
        margin: 0 10px;
        cursor: pointer;
        opacity: 0.2;
        &.active {
          opacity: 1;
          span {
            color: blue;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }

      //test dropdown
      .dropdown-language {
        position: relative;
        display: inline-block;
      }
      .dropdown-btn {
        padding: 10px 13px;
        width: 110px;
        background-color: #fff;
        /* font-family: "Courier New", Courier, monospace; */
        /* color: #fff; */
        border: none;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        align-items: center;
        span {
          padding-left: 5px;
        }
      }
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #fff;
        min-width: 130px;
        box-shadow: 0px 8px 16px 0px rgba(21, 18, 18, 0.2);
        z-index: 1;
        border-radius: 5px;
        padding: 10px 0;
        right: -15px;
      }
      .dropdown-content button {
        border: none;
        width: 100%;
        background-color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-family: "Courier New", Courier, monospace;
      }
      .dropdown-content button img {
        margin-right: 5px;
      }
      .dropdown-content button:hover {
        background-color: #c1c1c1;
      }
      .dropdown-language:hover .dropdown-content {
        display: block;
      }
    }
  }
}
.offcanvas-start {
  width: 300px !important;
}
.item {
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  &.active {
    span {
      color: blue;
    }
  }
  &:hover {
    color: rgba(69, 195, 210, 1.00);
  }
}
.home-header-banner {
  background: url("../../assets/gia-dinh-hanh-phuc-22.jpg");
  height: 485px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  .content-up {
    background-image: linear-gradient(
      rgba(126, 126, 126, 0.25),
      rgba(255, 255, 255, 0.1)
    );
    height: 65%;
    padding-top: 15px;
    .title1 {
      color: white;
      text-align: center;
      font-size: 30px;
      text-shadow: 1px 1px 1px #333;
      margin-top: 30px;
      margin-bottom: 5px;
    }
    .title2 {
      color: white;
      text-align: center;
      font-size: 30px;
      text-shadow: 1px 1px 1px #333;
      margin-bottom: 15px;
    }
    .search {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 10px;
      border-radius: 25px;
      background: #f7d800;
      i {
        margin: 0 5px;
      }
      .css-2b097c-container {
        width: 360px;
        // background: transparent;
        // border: none;
        // outline: none;
        .css-yk16xz-control {
          background: transparent;
          border: none;
          outline: none;
          .css-tlfecz-indicatorContainer {
            color: black;
          }
        }
      }
    }
  }
  .content-down {
    height: 35%;
    padding-bottom: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 1)
    );
    .options {
      text-align: center;
      display: flex;
      gap: 30px;
      justify-content: center;
      .option-child {
        width: 100px;
        cursor: pointer;
        .icon-child {
          background: #d9d9d9;
          width: 80px;
          height: 80px;
          padding: 20px;
          border-radius: 50%;
          text-align: center;
          margin: 0 auto;
          i {
            color: #49bce2;
            font-size: 40px;
          }
        }
        .text-child {
          font-size: 17px;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            color: #49bce2;
          }
        }
      }
    }
  }
}
